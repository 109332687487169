<template>
  <LxpMobileHeader v-if="isMobile">
    <template v-slot:left>
      <div class="util util-back">
        <router-link :to="{name: 'LearnBoard'}" class="util-actions util-actions-back">
          <i class="icon-history-back"></i>
        </router-link>
      </div>
    </template>
    <template v-slot:right>
      <div v-if="attachments.length > 0" class="util util-download">
        <a href="javascript:" class="util-actions util-actions-download" @click.stop="toggles.attachment = !toggles.attachment">
          첨부파일 <i class="icon-download"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-sdl-mento" @click="closeToggles(toggles)">
    <!-- main-header -->
    <template v-if="!isMobile">
      <div class="main-header">
        <!-- main-header > header-top -->
        <div class="header-top main-component">
          <div class="header-column">
            <router-link :to="{ name: 'LearnBoard' }" class="page-nav">
              <i class="icon-kb-nav-arrow"></i>
              <span class="text">이전</span>
            </router-link>
          </div>

          <div v-if="attachments.length > 0" class="header-column">
            <div class="kb-form-dropdown download-dropdown" :class="{'is-active': toggles.attachment}" data-offset="bottom-right">
              <button class="kb-form-dropdown-btn kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click.stop="toggles.attachment = !toggles.attachment">
                <i class="icon-download32"></i><span class="text">첨부파일</span>
              </button>
              <div class="kb-form-dropdown-options">
                <div class="dropdown-option">
                  <ul class="dropdown-option-list">
                    <li v-for="(attachment, index) in attachments" class="dropdown-option-item" :key="index">
                      <a href="javascript:" class="dropdown-option-link" :title="attachment.fileNm" @click.stop="downloadAttachment(attachment)">
                        <span class="dropdown-option-text">{{attachment.fileNm}}</span><span class="download-mb">{{ convertToStorageBytes(attachment.fileSz) }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- //main-header -->
      <!-- main-content -->
      <div class="main-content min-component">
        <div v-if="isReady" class="board-view-container">
          <div class="view-header">
            <h4 v-if="params.cateNm" class="category">{{ params.cateNm }}</h4>
            <h3 class="title">{{ params.title }}</h3>
            <p class="subtitle"><span class="text">{{ timestampToDateFormat(params.regDt, 'yyyy.MM.dd hh:mm')}}작성</span>
              <span class="text">{{ params.lrnerNm }} ・ {{ params.deptNm}} {{params.jbgdCd && params.jbgdCd.length >= 2 ? params.jbgdCd.substr(0, 2) : '' }}</span>
            </p>
          </div>
          <div class="view-body min-component">
            <div class="view-content">
              <div class="kb-table-template">
                <div class="kb-table kb-table-bordered editor-area ck-editor__editable" v-html="params.cn"></div>
              </div>
            </div>
          </div>
          <div v-if="session.lrnerId === params.lrnerId" class="view-bottom">
            <button class="kb-btn kb-btn-primary" @click="goEdit">
              <span class="text">수정하기</span>
            </button>
          </div>
        </div>
        <BoardComment
            :target-sn="lrnPostSn"
            div="learn"
        />
      </div>
    </template>
    <template v-else>
      <!-- main-content -->
      <div class="main-content min-component">
        <div v-if="attachments.length > 0"  class="kb-form-layer" :class="{'is-active': toggles.attachment}" @click="toggles.attachment = false">
          <div class="layer-container">
            <div class="layer">
              <div class="kb-form-layer-options">
                <div class="layer-option">
                  <ul class="layer-option-list">
                    <li v-for="(attachment, index) in attachments" class="layer-option-item" :key="index">
                      <a href="javascript:" class="layer-option-link" @click.stop="downloadAttachment(attachment)">
                        <span class="layer-option-text" :title="attachment.fileNm">{{attachment.fileNm}}</span><span class="text-muted">{{ convertToStorageBytes(attachment.fileSz) }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isReady" class="board-view-container">
          <div class="view-header view-header-snuggle">
            <div class="title-wrap">
              <h4 v-if="params.cateNm" class="category">{{ params.cateNm }}</h4>
              <h3 class="title" style="margin-top: 8px">{{ params.title }}</h3>
              <p class="subtitle">
                <span class="text">{{ timestampToDateFormat(params.regDt, 'yyyy.MM.dd hh:mm')}} 작성</span>
                <span class="text">{{ params.lrnerNm }} ・ {{ params.deptNm}} {{params.jbgdCd && params.jbgdCd.length >= 2 ? params.jbgdCd.substr(0, 2) : '' }}</span>
              </p>
            </div>
          </div>
          <div class="view-body min-component">
            <div class="view-content">
              <div class="kb-table-template">
                <div class="kb-table kb-table-bordered editor-area ck-editor__editable" v-html="params.cn"></div>
              </div>
            </div>
          </div>
          <div  class="view-bottom">
            <button class="kb-btn kb-btn-primary kb-btn-w100" @click="goEdit">
              <span class="text">수정하기</span>
            </button>
          </div>
          <BoardComment
              :target-sn="lrnPostSn"
              div="learn"
          />
        </div>
      </div>
    </template>
    <!-- //main-content -->
  </main>
</template>
<script>

import {computed, reactive, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {
  collectionCheck,
  convertToStorageBytes,
  getItem,
  lengthCheck,
  setParams,
  timestampToDateFormat,
} from '@/assets/js/util';
import {ACT_GET_LRN_BOARD} from '@/store/modules/board/board';
import {useAlert} from '@/assets/js/modules/common/alert';
import BoardComment from '@/components/board/BoardCommnet';
import navigationUtils from '@/assets/js/navigationUtils';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import ApiService from '@/assets/js/api.service';
import {closeToggles} from '@/assets/js/ui.init';

export default {
  name: 'LearnBoardView',
  components: {LxpMobileHeader, BoardComment},
  setup() {

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const {showLoading, hideLoading} = useAlert();

    const session = computed(() => store.state.auth.session);
    const isReady = ref(false);

    const lrnPostSn = computed(() => route.params.lrnPostSn > 0 ? parseInt(route.params.lrnPostSn) : 0);
    const params = reactive({
      lrnPostSn: 0,
      title: '',
      cn: '',
      distCrseSn: 0,
      distObjSn: 0,
      lrnerNm: '',
      lrnerId: '',
      deptNm: '',
      jbgdCd: '',
      regDt: 0,
      cateNm: ''
    });
    const attachments = ref([]);
    const toggles = reactive({
      attachment: false
    })

    const goEdit = () => {
      router.push({name: 'LearnBoardWrite'});
    }

    const readBoard = lrnerId => {
      if(session.value.lrnerId !== lrnerId){
        ApiService.put(`/v1/app/learns/boards/${lrnPostSn.value}/inq`).then(() => {}).catch(() => {});
      }
    }

    const downloadAttachment = attachment => {
      showLoading();
      ApiService.download(`/v1/app/learns/boards/atchs/${attachment.lrnPostFileAtchSn}/download`,
          null, null, attachment.fileNm, () => {hideLoading()}
      );
    }

    if (lrnPostSn.value > 0) {
      showLoading();
      store.dispatch(`board/${ACT_GET_LRN_BOARD}`, lrnPostSn.value).then(res => {
        if(lengthCheck(res)){
          const item = getItem(res);
          setParams(params, item);
          if (collectionCheck(item.files, 'lrnPostFileAtchSn')) {
            attachments.value = item.files;
          }
          readBoard(item.lrnerId);
          isReady.value = true;

        }

        hideLoading();
      });
    }

    return {
      isReady,
      session,
      lrnPostSn,
      params,
      attachments,
      toggles,
      goEdit,
      downloadAttachment,
      timestampToDateFormat,
      convertToStorageBytes,
      closeToggles,

      isMobile: navigationUtils.any(),
    }
  }
};
</script>
